a,
u {
    text-decoration: none;
}

.bar-of-progress {
    z-index: 50;
}

.body-bodyContainer {
    max-width: 100%;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 20px;
    /* overflow: hidden; */
}
.body-container .body {
    position: relative;
    text-overflow: wrap;
    margin: auto;
    font-size: 14px !important;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    color: #333 !important;
    line-height: 1.7 !important;
}

.body-container .body p {
    font-size: 14px !important;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    color: #333 !important;
    font-weight: 400 !important;
    line-height: 1.8 !important;
    text-align: justify;
}
.body-container .body li {
    font-size: 14px !important;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    color: #333 !important;
    font-weight: 400 !important;
    line-height: 1.8 !important;
    text-align: justify;
}
.body-container .body .image.image-style-align-right {
    width: 48%;
    float: right;
    padding: 0px 0px 20px 20px;
}
.body-container .body .image.image-style-align-left {
    width: 48%;
    float: left;
    padding: 0px 20px 20px 0px;
}
.body-container .body figure {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    width: 100%;
}
.body-container .body .image {
    max-width: 100%;
    /* position: absolute; */
    /* width: 100%; */
    /* height: 100%;
    top: 0;
    left: 0; */
}
.body-container .body img {
    max-width: 100%;
    /* width: 100%; */
}
.body-container .body table,
.body-container .body th,
.body-container .body td {
    margin: auto;
    padding: 10px;
    line-height: 14px;
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #ebebeb;
    border-collapse: collapse;
    text-align: justify;
}
.ck-blurred {
    border: 1px solid black !important;
    border-radius: 5px;
}
.ck-focused {
    background-color: #fff;
}
.ck-body-wrapper {
    z-index: 3000;
    display: block;
    position: absolute;
}
.ytp-large-play-button ytp-button {
    display: none !important;
}

.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

.ck.ck-content ul {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
    list-style-type: decimal;
}
.ck.ck-content ol {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}

.navbar li > ul {
    transform: translatex(100%) scale(0);
}
.navbar li:hover > ul {
    transform: translatex(101%) scale(1);
}
.navbar .group:hover .group-hover\:scale-100 {
    transform: scale(1);
}
.navbar .group:hover .group-hover\:-rotate-180 {
    transform: rotate(180deg);
}
.navbar .scale-0 {
    transform: scale(0);
}
.navbar .min-w-32 {
    min-width: 4rem;
}
.navbar .menuname {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    color: #7c7c7c;
    white-space: nowrap;
    padding-top: 7px;
    padding-bottom: 7px;
    /* padding: 5px; */
}

@font-face {
    font-family: 'SFPRODISPLAY';
    src: url('/fonts/SFPRODISPLAY/SFPRODISPLAYBLACKITALIC.OTF');
    font-style: italic;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'SFPRODISPLAY';
    src: url('/fonts/SFPRODISPLAY/SFPRODISPLAYHEAVYITALIC.OTF');
    font-style: italic;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'SFPRODISPLAY';
    src: url('/fonts/SFPRODISPLAY/SFPRODISPLAYSEMIBOLDITALIC.OTF');
    font-style: italic;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'SFPRODISPLAY';
    src: url('/fonts/SFPRODISPLAY/SFPRODISPLAYLIGHTITALIC.OTF');
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'SFPRODISPLAY';
    src: url('/fonts/SFPRODISPLAY/SFPRODISPLAYLIGHTITALIC.OTF');
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'SFPRODISPLAY';
    src: url('/fonts/SFPRODISPLAY/SFPRODISPLAYULTRALIGHTITALIC.OTF');
    font-style: italic;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'SFPRODISPLAY';
    src: url('/fonts/SFPRODISPLAY/SFPRODISPLAYBOLD.OTF');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'SFPRODISPLAY';
    src: url('/fonts/SFPRODISPLAY/SFPRODISPLAYMEDIUM.OTF');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'SFPRODISPLAY';
    src: url('/fonts/SFPRODISPLAY/SFPRODISPLAYREGULAR.OTF');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
